//@import "angled-edges"; //https://github.com/josephfusco/angled-edges

/**
 * Clamps, aka truncates, multi-line text. Note that non-webkit browsers will
 * not see the ellipsis ¯\_(ツ)_/¯
 * @param {Value}          $font-size     - Font size of the text
 * @param {Unitless Value} $line-height   - Line height of the text;
 * @param {Number}         $lines-to-show - Number of lines to show
 * @example scss
 * p {
 *    @include line-clamp($font-size: 16px, $line-height: 1.5, $lines-to-show: 3);
 * }
 */
@mixin line-clamp($font-size, $line-height, $lines-to-show) {

	@if unitless($line-height) == false {
		$line-height: create-unitless-line-height($font-size, $line-height);
	}

	// Fallback for non-webkit browsers
	display: block;

	display: -webkit-box;
	font-size: $font-size;

	// Fallback for non-webkit browsers
	height: ($font-size * $line-height * $lines-to-show);
	line-height: $line-height;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: $lines-to-show;
}

/**
 * WebKit font-smoothing
 *------------------------------------------------

 * References:
 *
 * 1. http://maxvoltar.com/sandbox/fontsmoothing/
 * 2. http://maxvoltar.com/archive/-webkit-font-smoothing
 *
 * Values: none, antialiased (default), subpixel-antialiased
 */
@mixin font-smoothing($value: antialiased) {

	@if $value == antialiased {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	@else {
		-webkit-font-smoothing: subpixel-antialiased;
		-moz-osx-font-smoothing: auto;
	}
}

