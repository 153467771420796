table {
	border-collapse: collapse;
	margin: $small-spacing 0;
	width: 100%;


	th {
		padding: $small-spacing;
		text-align: left;
		font-family: $gotham;
	}

	td {
		padding: $small-spacing;
	}

	tr,
	td,
	th {
		vertical-align: middle;
	}

	tbody tr:nth-child(odd) {
		background-color: palette(grey, light);
	}
}

.wp-block-table {
	overflow: auto;

	td,
	th {
		border: none;
	}

	thead,
	tfoot {
		border: none;
	}
}

.is-style {

	&-stripes {

		table {
			border: none;

			th {
				background-color: palette(grey, black);
				color: palette(white, base);
			}

			thead {

				th {
					color: palette(white, base);
				}
			}

			tbody {

				tr,
				td {
					border-bottom: none;
				}
			}
		}
	}

	&-plain {

		table {
			border: none;

			/* stylelint-disable */
			th {
				background-color: palette(grey, light);
				color: palette(black, charcooal);
			}
			/* stylelint-enable */

			tbody {

				tr {
					border-bottom: 1px solid #e3e3e3;
				}
			}

			tbody tr:nth-child(odd) {
				background-color: palette(white, base);
			}
		}
	}

	&.costs {

		table {
			line-height: 1.5;

			td,
			th {
				padding: 0.9em;
			}
		}

		thead {

			th {

				&:first-child {
					width: 75%;
				}
			}
		}
	}
}
