.loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    display: block;
    margin: 0 auto;
    transition: 0.2s opacity ease-in-out;

    &.hide {
        opacity: 0;
    }

    div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #fff;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);

        &:nth-child(1) {
            left: 8px;
            animation: load-1 0.6s infinite;
        }

        &:nth-child(2) {
            left: 8px;
            animation: load-2 0.6s infinite;
        }

        &:nth-child(3) {
            left: 32px;
            animation: load-2 0.6s infinite;
        }

        &:nth-child(4) {
            left: 56px;
            animation: load-3 0.6s infinite;
        }
    }
}

@keyframes load-1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes load-2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
@keyframes load-3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}


.loadingSelector {
    height: calc( 100vh - 120px );
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    transition: 0.2s ease-in-out opacity;
    text-align: center;

    &.hide {
        opacity: 0;
        user-select: none;
        pointer-events: none;
    }
}

