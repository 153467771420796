.video-controls {
	position: absolute;
	left: 16px;
	top: 16px;
	z-index: 30;

	.toggle_playback {
		border-radius: 50%;
		height: 32px;
		width: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: palette(green, base);
		border: none;
		cursor: pointer;
	}

	img {
		display: inline-block;
		width: 16px;
	}
}

.button {
	-webkit-appearance: none;
	border: 0;
	border-radius: 0;
	cursor: pointer;

	@include font-smoothing;

	max-width: 340px;
	width: 100%;
	min-width: 100px;
	border-radius: 30px;
	background-color: var(--sidebar-background);
	font-size: 16px;
	color: var(--white);
	font-family: $gotham;
	line-height: 1.5;
	text-align: center;
	padding: 12px 8px;
	white-space: normal;
	display: block;
	margin-bottom: 8px;
	margin-left: auto;
	margin-right: auto;
	transition: 0.2s ease-in-out opacity;
	display: flex;
	align-items: center;
	justify-content: center;
	display: flex;
    padding: 16px;

	svg {
		margin-left: 8px;
		width: 16px;
		fill: var(--yellow);
	}

	&.login {
		background-color: var(--yellow);
		color: var(--sidebar-background) !important;
		border-color: var(--yellow);
	}

	&.return {
		display: flex;
		align-items: center;
		width: initial;
		margin: 0;

		img {
			width: 12px;
			margin-right: 8px;
			margin-left: 4px;
			display:block;
		}
	}

	&.restrict {
		padding: 8px 32px;
		max-height: 40px;
	}

	&.add {
		padding: 8px 0;
		max-width: 100px;
	}

	&.continue {
		position: sticky;
		top: initial;
		bottom: 7px;
		z-index: 90000000;
	}

	&.grey {
		background-color: var(--grey-med);
	}

	&.Facebook {
		background-color: var(--facebook-blue);
		color: var(--white);
		border-color: var(--facebook-blue);
	}

	&.Google {
		background-color: var(--white);
		color: var(--charcoal);
		border-color: var(--white);
	}

	&.Apple {
		background-color: var(--black);
		color: var(--white);
		border-color: var(--black);
	}

	&.outline {
		border-width: 1px;
		border-style: solid;
		background-color: transparent;
		color:  var(--yellow) !important;
	}

	&.loadBtn {
		margin-top: 40px;

		@media ( max-width: 790px ) {
			margin-bottom: 80px;
		}
	}

	&.disabled {
		background-color: #7d7a45;
		cursor: not-allowed;
	}

	&:hover,
	&:focus {
		opacity: 0.6;
	}
}

.back {
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		margin-right: 8px;
	}
}

.close {
	border-radius: 16px;
	background-color: rgba(255, 255, 255, 0.2);
	font-size: 12px;
	color: #ffffff;
	text-align: center;
	padding: 8px 12px;
}

.remove {
	position: absolute;
	right: 8px;
	top: 8px;
	width: 16px;
	height: 16px;
	opacity: 1;
	background-color: var(--charcoal);
	opacity: 0.6;
	padding: 12px;
	border-radius: 50%;
	transition: 0.2s ease-in-out opacity;

	&:before,
	&:after {
		position: absolute;
		left: 50%;
		top: 50%;
		content: ' ';
		height: 12px;
		width: 2px;
		background-color: var(--white);
	}

	&:before {
		transform: translateX(-50%) translateY(-50%) rotate(-90deg);
	}

	&:after {
		transform: translateX(-50%) translateY(-50%) rotate(-90deg);
	}


	&:hover,
	&:focus {
		opacity: 1;
	}
}

.inactive {
	opacity: 0.3;

	.remove {

		&:before {
			transform: translateX(-50%) translateY(-50%) rotate(90deg);
		}

		&:after {
			transform: translateX(-50%) translateY(-50%) rotate(0);
		}
	}
}
