// Breakpoints
$medium-screen: 600px;
$large-screen: 900px;
$desktop-s: 1024px;
$tablet: 768px;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 0;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

:root {
	--yellow: #feee00;
	--font: #21221f;
	--info: #488ecb;
	--sidebar-fade: rgba(53, 54, 55, 0.6);
	--sidebar-background: #353637;
	--sidebar-colour: #ffffff;
	--turquoise: #20b9a1;
	--turquoise-dark: #188178;
	--error: #F04F48;
	--success: #4BC074;
	--white: #ffffff;
	--black: #000000;
	--input-text: #a5a2a2;
	--charcoal: #21221f;
	--facebook-blue: #4267b2;
	--base: #353637;
	--semi-yellow: rgba(254, 238, 0, 0.2);
	--grey-med: #646464;
	--grey-light: #F1F2F3;
	--blue: #0b72b1;
	--itemSize: calc(5rem + 5vw);
	--x: 0%;
	--y: 0%;
	--translateX: calc(-50% - var(--x) * 0.3);
	--translateY: calc(-50% - var(--y) * 0.3);
	--scale: 1;

	@media (max-width: $large-screen) {
		--itemSize: 10rem;
		--responsiveness: 1;
	}
}

.color-white {
	color: var(--white);
}

.color-yellow {
	color: var(--yellow);
}

// Colors
$palette: (
	blue: (
		info: #488ecb,
		mid: #0b72b1,
		dark: #1e3c71
	),
	orange:(
		base: #faa719
	),
	green: (
		success: #4bc074,
		base: #20b9a1,
		dark: #188178
	),
	yellow: (
		base: #feee00,
		warning: #ffed00
	),
	red: (
		danger: #f04f48,
		coral: #de655e
	),
	grey: (
		light: #f1f2f3,
		mid: #a5a2a2,
		mild: #dadada,
		black: #353637
	),
	black: (
		base: #000,
		charcoal:#21221f
	),
	white: (
		base: #fff,
		transparent: rgba(255, 255, 255, 0)
	)
);

// Font Colors
$base-font-color: palette(black, charcoal);
$action-color: var(--turquoise);

// Border
$base-border-color: palette(black, charcoal);
$base-border: 2px solid palette(black, charcoal);
$form-box-shadow-focus: palette(grey, light);

// Background Colors
$base-background-color: palette(white, base);
$secondary-background-color: tint($base-border-color, 75%);

// Animations
$base-duration: 150ms;
$base-timing: ease;

$container-medium: 948px - ( 2 * 32px );
$container-large: 1200px;

.container {
	max-width: 1200px;
	margin: 0 auto;

	&--medium {
		max-width: $container-medium;
	}

	&--large {
		max-width: 1200px;
		padding: 0 16px;
	}
}

@keyframes fadeIn {
	from {opacity: 0;}
	to {opacity: 1;}
}
