html {
	min-height: 100vh;
}

body {
	height: 100vh;
    width: 100vw;
    overflow: auto;
}

body > .wrapper {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

body {
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
}

.content {
	flex: 1 0 auto;
}

.footer {
	flex-shrink: 0;
}

main[role="main"] {
	flex: 1 0 auto;
}

#root {
	position: relative;
}
