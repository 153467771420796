fieldset {
	background-color: transparent;
	border: 0;
	margin: 0;
	padding: 0;
}

legend {
	font-weight: 600;
	margin-bottom: $small-spacing / 2;
	padding: 0;
}

label {
	display: block;
	width: 100%;
	font-size: 0.75em;
	font-weight: 600;
	color: palette(black, charcoal);
	margin-bottom: $small-spacing / 2;
	text-align: left;

	&.checkbox {
		font-family: $gotham;
	}
}

textarea {
	resize: vertical;
}

input[type="search"],
input[type="text"],
input[type="email"],
input[type="password"],
select {
	display: block;
	appearance: none;
	border: none;
	width: 100%;
	margin-bottom: 8px;
	height: 48px;
	text-indent: 16px;
	border-radius: 8px;
	background-color: var(--font);
	background-size: cover;
	color: var(--white);
	line-height: 1.5;
	font-size: 16px;
	font-family: $family-primary;

	&::placeholder {
		color: var(--input-text);
	}
}

select {
	color: var(--white);

	option {
		color: var(--font);
	}
}

.revert {

	max-width: 320px;
	margin:0 auto;

	input[type="search"],
	input[type="text"],
	input[type="email"],
	input[type="password"],
	select {
		background-color: var(--white);
		color: var(--font);
	}
}

select {
	border: 2px solid var(--font);
	background-color: transparent;
	background-image: url(../../img/icons/dropdown.svg);
	background-size: 16px;
	background-position: right 16px center;
	background-repeat: no-repeat;
}

.errors {
	margin-bottom: 16px;
}

.alert {
	padding: 8px 12px;
	border-radius: 4px;
	margin-bottom: 8px;

	&.error {
		background-color: var(--error);
	}

	&.success {
		background-color: var(--success);
	}
}

.search {
	width: 100%;
	display: grid;
	grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));

	fieldset {
		width: 100%;
		position: relative;
	}

	select,
	input {
		width: 100%;
		margin: 0;
	}

	input {
		margin: 0;
		padding-right: 40px;
	}

	@media ( max-width: 728px ) {
		grid-template-columns: repeat(1, 1fr);
	}
}

.search-icon {
	background: transparent;
	border: none;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	width: 40px;
	display: flex;
    align-items: center;
    justify-content: center;

	img {
		width: 16px;
		display: block;
	}
}

