.detail,
.wrapper {
    // position: absolute;
    position: relative;
    width: 99%;
    //max-width: 1200px;
    z-index: 19;

    &Title {
        font-family: $heading-font-family;
        font-size: 2rem;
    }

    .slide-count {
        width: 100px;
        align-items: center;
        justify-content: center;
    }

    .single {
        display: flex;
        height: 100%;
        position: absolute;
        width: 100%;
        padding-left: 8px;
        padding-right: 8px;
        display: flex;


        figure {
            border-radius: 10px;
            overflow: hidden;
            width: calc( 50% - 8px );
            margin-right: 8px;
            background-color: var(--charcoal);

            img {
                object-fit: cover;
                height: 100%;
                border-radius: 10px;
            }

            figcaption {
                position: absolute;
                bottom: 32px;
                z-index: 100;
                left: 50%;
                transform: translateX(-50%);
                font-size: 26px;
	            line-height: 1.5;
                text-align: center;
                max-width: calc( 100% - 120px );
                width: 100%;
            }
        }

        &.active {
            z-index: 10;

            figure {

                img {
                    width: 100%;
                }
            }
        }

        &.active + .single {

            figure {
                width: 50%;
                height: calc(100% - 40px );
                padding-left: 0;
                margin-left: -8px;
                margin-right: -8px;
                margin-top: 20px;
            }
        }
    }

    @media ( max-width: $large-screen ) {

        white-space: nowrap;

        .single {
            min-width: calc( 100% - 64px );
            flex-direction: column;
            position: relative;

            figure {
                position: relative;
                margin-bottom: 16px;
                width: 100%;
                overflow: initial;

                img {
					height: 300px;
                    width: 100%;
                    display: block;
                }
            }

            .content {
                padding-bottom: 80px;
                white-space: normal;
                width: 100%;
                margin-left: 0;

                .scroll {
                    height: initial;
                }
            }
        }
    }
}

.slide {

    .remove{
        z-index: 100;
		right: 1em;
		display: none;
    }

    figure {
        border-radius: 8px;
        overflow: hidden;
        backface-visibility: hidden;
        will-change: transform;

        img {
            border-radius: 8px;
            object-fit: cover;
            width: 100%;
            height: 100%;
            min-height: 280px;

            @media ( max-width: 1014px ) {
                max-height: 280px;
            }
        }

        &:after {
            background: linear-gradient(to top, --var(black), rgba( 0, 0, 0, 0) );
            content: "";
            bottom: -2px;
            left: 0;
            right: 0;
            background: linear-gradient(to top, black, transparent);
            width: 100%;
            height: 300px;
            position: absolute;
            border-radius: 10px;
            z-index: 10;
        }
    }

    figcaption {
        width: calc(100% - 140px);
        white-space: normal;

        display: none;
        z-index: 9999999999999999999999;
        position: absolute;
        bottom: 32px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }

    &.swiper-slide-active,
    &.swiper-slide-duplicate-active {
		figcaption,
		.remove  {
            display: block;
		}
    }
}

.detail-cards:not(.no-loop) {
    // display: grid;
    // grid-gap: 1rem;
    // grid-template-columns: repeat(auto-fit, minmax(470px, 1fr));
    white-space: normal;
    max-width: 100%;
    display: flex;
    flex-direction: column;

    @media ( min-width: 1014px ) {
        flex-direction: row;
    }

    .child {
        overflow: hidden;
        max-width: 100%;

        .swiper-slide {
            max-width: 100%;
        }

        &.figures {

            margin-bottom: 16px;
        }

        @media ( min-width: 1014px ) {

            width: 50%;

            &:first-child {
                margin-right: 1em;
            }

            &.contents {

                .swiper-container {
                    height: 100%;

                    .swiper-slide {
                        border-radius: 8px;
                        overflow: hidden;
                    }

                    .content {
                        height: 100%;
                    }
                }
            }

            &.figures {

                .swiper-slide {
                    cursor: pointer;
                    height: 100%;
                    max-height: 80vh;
					min-height: 80vh;
					transform: scale(0.95);
					border-radius: 8px;
					overflow: hidden;

                    figcaption {
                        width: 200%;
                    }

                    figure {
                        //transform: scaleX(2.8) scaleY(0.9);
                        transition: all 0.3s ease-in-out;
                        opacity: 0.8;
                        height: 100%;
                        position: absolute;

                        img {
                            height: 100%;
                            max-height: initial;
                            object-fit: cover;
                        }
                    }

                    &.swiper-slide-active,
                    &.swiper-slide-duplicate-active {
						z-index: 10000;
						width: 92% !important;
						margin-left: -29%;
						transform: scale(1);

                        figure {
							opacity: 1;
							width: 100%;
                            //transform: scaleX(2.8) scaleY(1);

                            img {
                                filter: initial;
                            }
                        }

					}

					&.swiper-slide-next {
						margin-left: -29%;
						border-radius: 8px;
						overflow: hidden;
					}

					&.swiper-slide-next,
					&.swiper-slide-prev {

						img {
							filter: blur(1.5rem);
						}

					}
                }
            }
        }
    }
}

.detail-cards {

	.content {
		background-color: var(--white);
		color: var(--base);
		padding: 20px;
		border-radius: 10px;

		.scroll {
			height: calc( 76vh - 20px );
			overflow: auto;
			margin-bottom: 16px;

            @media ( max-width: 769px ) {
                height: initial;
                overflow: initial;
            }
		}

		.subtitle {
			color: var(--turquoise);
			font-size: 1.75rem;
		}

		.button {
			margin-left: 0em;
		}

		a {
			text-decoration: underline;
			color: var(--turquoise);

			&:hover {
				color: var(--turquoise-dark);
			}
		}

		.content-text {

			h1,
			h2,
			h3,
			h4 {
				color: var(--turquoise-dark);
				font-family: $heading-font-family;
			}
		}
	}
}

.detailSelected {

	.space-between {

		.button {
			margin-left: 0.5em;
		}
	}

	.swiper-container {
		overflow: visible;

		.swiper-button-prev,
		.swiper-button-next {
			position: absolute;
			width: 2rem;
			height: 2rem;
			border-radius: 60%;
			background-color: var(--yellow);
			z-index: 9999999;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			top: initial;
			bottom: 16px !important;

			@media( max-width: 1014px) {
				top: 140px !important;
				transform: translateY(50%);
			}

			&::after {
				color:var(--black);
				font-size: 1rem;
			}
		}

		.swiper-button-prev {
			left: 40px;

			@media ( max-width: $large-screen) {
				left: 20px;
			}
		}

		.swiper-button-next {
			right: 40px;

			@media ( max-width: $large-screen ) {
				right: 20px;
			}
		}

		.swiper-slide {
			transition: all 0.2s ease-in;
		}

		.swiper-slide-active {
			//width: 100%;

			figure {
				position: relative;
				overflow: visible !important;

				img {
					position: relative;
					z-index: 1;
				}
			}
		}
	}
}

.detail-head {
	align-items: flex-start;
	flex-wrap: wrap;
	margin-bottom: 8px;

	.detailTitle {
		max-width: calc( 100% - 140px );
		white-space: normal;

		@media (max-width: 640px) {
			width: 100%;
			margin-bottom: 0.5em;
		}
	}

	padding-top: 60px;

    @media (max-width:769px) {
        padding-top: 0 !important;

        .detailTitle {
            display: none;
        }

        .detail-buttons {
            align-items: flex-end;
        }
    }
}

.courses {
	.detail-head {
		padding-top: 0;
	}
}

.detail-buttons {

	@media ( max-width: 790px ) {
		flex-direction: column;
		width: 100%;

		.slide-count {
			display: none;
		}

		.button {
			margin-bottom: 8px;
			max-width: initial;
			width: 100%;
			margin-left: 0 !important;
			max-width: 10em;
		}
	}
}

.single {
	position: relative !important;

	.content {
		width: calc( 50% - 8px );
	}
}

.content {

    ul {
        list-style: initial;
        margin-left: 16px;

        li {
            margin: 8px 0;
        }
    }

    figure {
        margin: 8px 0;
    }
}
