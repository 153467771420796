.nav {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 999999999999;

    ul {
        display: flex;
        justify-content: space-evenly;

        li {
            margin-right: 32px;
            display: flex;
            align-items: center;

            &:last-child {
                margin-right: 0;
            }

            &:hover,
            &:focus {

                svg {
                    opacity: 0.8;
                }
            }
        }
    }

    .icon {

        a {
            display: flex;
        }

        svg {
            height: 24px;
            transition: 0.2s opacity ease-in-out;
            &>path{
                fill: var(--white);
            }
        }
    }

    @media ( max-width: 789px ) {
        background-color: var(--white);
        position: fixed;
        top: initial;
        bottom: 16px;
        left: 32px;
        right: 32px;
        border-radius: 8px;
        padding: 0 12px;
        border-radius: 30px;
        box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.4);

        ul {

            li {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-right: 1px solid #f1f2f3;
                padding: 12px 0;
                margin: 0;

                &:last-child {
                    border-right: 0;
                }
            }
        }

        .icon svg > path {
            fill: var(--info);
        }
    }
}

.nav {
    opacity: 0;
    transition: 0.2s opacity ease-in-out;
}

.transform {
    transform: initial !important;
    position: relative !important;

    .nav {
        opacity: 1;
    }
}
