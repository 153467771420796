.logo {
    height: 62px;

    @media ( min-width: 969px ) {
        margin-top: -20px;
    }
}

.cursor {
    cursor: pointer;
    border: none;
}

.btn {
    background-color: rgba( 255, 255, 255, 0 );
    font-size: inherit;
    font-family: inherit;
    padding: 0;
    color: inherit;
}

.inline-or {
    margin: 16px auto;
    display: block;
    text-align: center;
}

.inline-block {
    display: inline-block;
}

.no-margin {
    margin: 0;
}
.hidden {
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
    border: 0;
}

.disabled {
    cursor: disabled;
    pointer-events: none;
    opacity: 0.6;
    user-select: none;
}

.text-center {
    text-align: center;
}

.underlined {
    text-decoration: underline;
}

.has-underline {
    position: relative;
    padding-bottom: 0.5em;

    &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 5rem;
        height: 0.25em;
        background-color: var(--yellow);
    }
}

.is-flex {
    display: flex;
}

.space-between {
    justify-content: space-between;
}

.grecaptcha-badge {
    display: none;
}

.dark {
    color: var(--font);
}

.no-mt {
    margin-top: 0;
}

.no-mb {
    margin-bottom: 0;
}


.skip-to {
    left:-999px;
    position:absolute;
    top: 24px;
    width:1px;
    height:1px;
    overflow:hidden;
    z-index:-999;

    &:focus,
    &:active {
        color: #fff;
        background-color:#000;
        height: auto;
        overflow:auto;
        padding: 4px;
        width: 100%;
        max-width: 300px;
        border-radius: 15px;
        border:4px solid yellow;
        text-align:center;
        font-size:1.2em;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%,0);
        z-index: 999999;
        opacity: 1;
    }
}
