.home {
    position: relative;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    text-align: center;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-color: var(--yellow);
    background-image: url(../../img/bg/home.webp);
    font-family: $gotham;
    min-height: 100vh;
    transition: 0.2s ease-in-out opacity;

    p {
        padding-top: 24px;
        margin-bottom: 0;
    }

    &.hide {
        opacity: 0;
        overflow: hidden;
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 400px;
        left: 0;
        right: 0;
        background-image: url(../../img/pattterns/crosses.webp);
        background-position: 0 18px;
        background-repeat: no-repeat;
        pointer-events: none;
        user-select: none;
    }

    &--footer {
        padding-top: 40px;

        .enter {
            padding-bottom: 60px;
            * {
                margin-bottom: 16px;
            }
        }
    }

    .container {
        max-width: 669px;
        padding: 80px 16px;
        position: relative;
        z-index: 10;
    }

    img {
        display: block;
        margin: 24px auto;
    }

    @media ( max-width: 768px ) {

        img {
            margin: 16px auto;
        }

        &--footer {
            padding-top: 26px;

            .enter {
                padding-bottom: 32px;
                * {
                    margin-bottom: 8px;
                }
            }
        }
    }
}

body.noWebp {

    .home {
        background-image: url(../../img/bg/home.png);

        &:after {
            background-image: url(../../img/pattterns/crosses.png);
        }
    }
}
