.strength {
	width: calc( 100% - 4px );
    height: 4px;
    background-color: #f2f2f2;
    border-radius: 3px;
    overflow: hidden;
    margin: 12px auto;
	opacity: 0.3;
	transition: opacity 0.2s ease-in-out;

	span {
		height: 100%;
		display: block;
	}

	&.fail {
		opacity: 0.9;
		span {
			background-color: #F04F48;
			width: 25%;
		}
	}

	&.good {
		opacity: 0.9;
		span {
			background-color: #FFED00;
			width: 50%;
		}
	}

	&.pass {
		opacity: 0.9;
		span {
			background-color: #4BC074;
			width: 100%;
		}
	}
}
