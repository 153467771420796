$font-stack-system: gotham-book, -apple-system,
	"BlinkMacSystemFont", "Segoe UI", "Roboto",
	"Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue",
	sans-serif;
$family-primary: $font-stack-system;
$base-font-family: $font-stack-system;

$heading-font-family: gotham-black, -apple-system,
	"BlinkMacSystemFont", "Segoe UI", "Roboto",
	"Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue",
	sans-serif;
$family-secondary: $heading-font-family;

$gotham: gotham, -apple-system,
	"BlinkMacSystemFont", "Segoe UI", "Roboto",
	"Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue",
	sans-serif;

.is-family-primary {
	font-family: $family-primary !important;
}

.is-family-secondary {
	font-family: $family-secondary !important;
}

.is-family-tertiary {
	font-family: $gotham !important;
}

$column-gap: 16px 8px;

@font-face {
	font-family: gotham-black;
	src: url(../../fonts/woff2/Gotham-Black.woff2) format("woff2"), url(../../fonts/otf/Gotham-Black.otf) format("OTF");
	font-display: swap;
}

@font-face {
	font-family: gotham;
	src: url(../../fonts/woff2/Gotham-Medium.woff2) format("woff2"), url(../../fonts/otf/Gotham-Medium.otf) format("OTF");
	font-display: swap;
}

@font-face {
	font-family: gotham-book;
	src: url(../../fonts/woff2/Gotham-Book.woff2) format("woff2"), url(../../fonts/otf/Gotham-Book.otf) format("OTF");
	font-display: swap;
}

body {
	color: $base-font-color;
	font-family: $base-font-family;
	font-size: $base-font-size;
	line-height: $base-line-height;
	background-color: var(--base);
	@include font-smoothing;
}

p {
	margin: 0 0 $small-spacing;
}

a,
.cursor {
	text-decoration: none;
	transition: opacity 0.2s ease-in-out;

	&:active,
	&:focus,
	&:hover {
		opacity: 0.6;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $heading-font-family;
	margin: 1.25em 0 $small-spacing;
}

p,
.base-text {
	margin: 0 0 $small-spacing;
	font-weight: 300;
	font-size: 16px;
	line-height: 24px;
}

hr {
	border-bottom: $base-border;
	border-left: 0;
	border-right: 0;
	border-top: 0;
	margin: $base-spacing 0;
}

label {
	cursor: pointer;
}


// H1 styling.
h1,
h2.is-1,
h3.is-1,
h4.is-1,
h5.is-1,
h6.is-1,
p.is-1,
span.is-1,
dt.is-1,
dd.is-1,
label.is-1,
button.is-1,
small.is-1,
li.is-1,
cite.is-1 {

	font-size: 48px;
	line-height: 56px;

	@media ( max-width: $tablet ) {
		font-size: 26px; // is-3
		line-height: 1.5; // is-3

		&--mobile {
			font-size: 48px !important;
			line-height: 56px !important;
		}
	}
}

// H2 styling.
h1.is-2,
h2,
h3.is-2,
h4.is-2,
h5.is-2,
h6.is-2,
p.is-2,
span.is-2,
dt.is-2,
dd.is-2,
label.is-2,
button.is-2,
small.is-2,
li.is-2,
cite.is-2 {
	font-size: 40px;
	line-height: 48px;

	@media ( max-width: $tablet ) {
		font-size: 24px; // is-4
		line-height: 32px; // is-4
	}

	&--mobile {

		@media ( max-width: $tablet ) {
			font-size: 40px !important;
			line-height: 48px !important;
		}
	}
}

// H3 styling.
h1.is-3,
h2.is-3,
h3,
h4.is-3,
h5.is-3,
h6.is-3,
p.is-3,
span.is-3,
dt.is-3,
dd.is-3,
label.is-3,
button.is-3,
small.is-3,
li.is-3,
cite.is-3 {
	font-size: 26px;
	line-height: 1.5;

	@media ( max-width: $tablet ) {
		font-size: 18px; // is-5
		line-height: 24px; // is-5
	}

	&--mobile {

		@media ( max-width: $tablet ) {
			font-size: 26px !important;
			line-height: 1.5 !important;
		}
	}
}

// H4 styling.
h1.is-4,
h2.is-4,
h3.is-4,
h4,
h5.is-4,
h6.is-4,
p.is-4,
span.is-4,
dt.is-4,
dd.is-4,
label.is-4,
button.is-4,
small.is-4,
li.is-4,
cite.is-4 {
	font-size: 24px;
	line-height: 32px;
	letter-spacing: -0.5px;

	@media ( max-width: $tablet ) {
		font-size: 16px;
		line-height: 24px;
	}

	&--mobile {

		@media ( max-width: $tablet ) {
			font-size: 24px !important;
			line-height: 32px !important;
		}
	}
}

// H5 styling.
h1.is-5,
h2.is-5,
h3.is-5,
h4.is-5,
h5,
h6.is-5,
p.is-5,
span.is-5,
dt.is-5,
dd.is-5,
label.is-5,
button.is-5,
small.is-5,
li.is-5,
cite.is-5 {
	font-size: 18px;
	line-height: 24px;

	&--mobile {

		@media ( max-width: $tablet ) {
			font-size: 18px !important;
			line-height: 24px !important;
		}
	}
}

// H6 Styling.
h1.is-6,
h2.is-6,
h3.is-6,
h4.is-6,
h5.is-6,
h6,
p.is-6,
span.is-6,
dt.is-6,
dd.is-6,
label.is-6,
button.is-6,
small.is-6,
li.is-6,
cite.is-6 {
	font-size: 16px;
	line-height: 24px;

	&--mobile {

		@media ( max-width: $tablet ) {
			font-size: 16px !important;
			line-height: 24px !important;
		}
	}
}

// Small Styling.
h1.is-7,
h2.is-7,
h3.is-7,
h4.is-7,
h5.is-7,
h6.is-7,
p.is-7,
span.is-7,
dt.is-7,
dd.is-7,
label.is-7,
button.is-7,
small,
li.is-7,
cite.is-7 {
	font-size: 12px;
	line-height: 16px;

	&--mobile {

		@media ( max-width: $tablet ) {
			font-size: 12px !important;
			line-height: 16px !important;
		}
	}
}

.meta {
	margin-bottom: 16px;
}
