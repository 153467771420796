.sidebar {
    user-select: none;
    pointer-events: none;
    overflow: hidden;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999999999999;

    a {
        color: var(--yellow);
    }

    &--bg {
        opacity: 0;
        background-color: var(--sidebar-fade);
        width: 100%;
        top: 0;
        position: absolute;
        z-index: 60;
        left: 0;
        right: 0;
        bottom: 0;
        transition: 0.2s ease-in-out opacity;
    }

    &--header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 0;

        &.only-child {
            justify-content: flex-end;
        }
    }

    &--content {
        background-color: var(--sidebar-background);
        color: var(--sidebar-colour);
        z-index: 200;
        overflow: auto;
        width: 485px;
        transform: translate(100%);
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        transition: 0.2s transform ease-in-out 0.1s, 0s visibility ease-in-out 1s;
        display: grid;
        place-items: center left;
        padding: 38px 72px;
        backface-visibility: hidden;
        will-change: transform visibility;
        visibility: hidden;

        @media (prefers-reduced-motion: reduce) {
            transition-duration: 1ms;
        }

        > div {
            width: 100%;
        }

        .todo {
            margin-top: 8px;
            margin-left: 16px;
        }

        @media ( max-width: 768px ) {
            width: 100%;
            padding: 24px;
            transform: translateY(100vh);
            place-items: flex-start;
            max-height: 100%;
            overflow: auto;
            overflow: hidden;
            position: fixed;
            top: 0;
            max-height: 90vh;
        }
    }

    &.open {
        user-select: initial;
        pointer-events: initial;

        .sidebar--bg {
            opacity: 1;
        }

        .sidebar--content {
            transform: translate(0);
            visibility: visible;
            transition: 0.2s transform ease-in-out 0.1s, 0s visibility ease-in-out 0s;

            @media ( max-width: 768px ) {
                transform: translateY(10vh);
                overflow: auto;
            }
        }
    }

    .shareLink {
        margin-bottom: 16px;
    }

    .copy {
        position: relative;

        input {
            padding-right: 40px;
        }

        svg {
            position: absolute;
            top: 10px;
            width: 24px;
            right: 12px;
        }
    }

    .external_menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li {
            padding: 4px 8px;

            a {
                color: var(--white);
            }
        }
    }

    .meta {
        padding: 16px 0;
        text-align: center;
        display: block;

        p {
            margin-bottom: 0;
        }
    }

    .buttons {
        padding: 24px 0;
    }
}


.tablets {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;

    & > div {
        display: block;
        width: calc(50% - 8px);
        margin-top: 8px;
        margin-bottom: 8px;

        &:nth-child(odd):not(:last-child) {
            margin-right: 8px;
        }
    }
}

.tablet {

    a,
    &.wechat {
        display: block;
        text-align: center;
        padding: 12px 24px;
        border-radius: 30px;
        background-color: var(--yellow);
        color: var(--charcoal);
    }

    &.whatsapp a {
        background-color: #24d366;
    }

    &.messenger a {
        background-color: #0078FF;
        color: var(--white);
    }

    &.imessage a {
        background-color: var(--black);
        color: var(--white);
    }

    &.wechat {
        background-color: #07c160 !important;

        a {
            background-color: #07c160 !important;
            padding: 0;
        }

        .wechat-content {
            display: none;
            margin-top: 8px;

            &.open {
                display: block;
            }
        }
    }

    &.unibuddy a {
        background-color: #0CCCC5;
    }
}
