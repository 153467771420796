.prospectus-header {
    margin-bottom: 27vh;
}

.prospectus-main {
    background-color: var(--base);
    color: var(--white);
    height: 100vh;
    width: 100%;
    overflow: auto;
    padding: 40px 24px 80px;
    position: absolute;
    top: 0;
    z-index: 10;
    box-shadow: 1px 4px 30px 5px rgba( 0, 0, 0, 0.48);
    backface-visibility: hidden;

    &:not(.detailSelected) {
        background-image: url(../../img/pattterns/mesh-dark.webp);
        background-repeat: repeat;
        background-position: center;
    }

    &.detailSelected {
        background-image: url(../../img/bg/courses.webp);
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;

        .infront {
            padding-bottom: 80px;
            padding-left: 24px;
            padding-right: 24px;

            &:after {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                height: 75%;
                left: 0;
                background-image: url(../../img/pattterns/mesh-dark.webp);
                mask-image: linear-gradient(black 0%, rgba(0, 0, 0, 0) 100%);
                content: "";
                opacity: 1!important;
                transform: rotate(180deg);
                width: 100%;
            }
        }

        &:before,
        &:after {
            opacity: 0;
            pointer-events: none;
        }

        &:after {
            position: absolute;
            height: initial !important;
        }
    }

    .infront {
        z-index: 20;
        position: relative;
    }

    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            0deg,
            rgba(53, 54, 55, 0) 3%,
            rgba(53, 54, 55, 0.95) 59%,
            #353637 90%,
            rgba(53, 54, 55, 0) 100%
        );
        z-index: 1;
        opacity: 0.8;
    }

    &:before {
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../img/bg/prospectus-bg-2.webp);
        background-position: top center;
        background-size: cover;
        z-index: 10;
    }

    &:after {
        transform: rotate(180deg);
        bottom: 0;
        opacity: 1;
        z-index: 11;
        height: calc( 100% + 340px );
    }

    .container {
        position: relative;
        z-index: 20;
    }

    @media ( max-width: 790px ) {
        padding-top: 20px;
    }
}


.prospectus-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 130px;
    z-index: 10;
    background: linear-gradient(0deg, rgba(53, 54, 55, 0) 3%, rgba(53, 54, 55, 0.95) 84%, #353637 100% );

    @media (max-width: 769px) {
        height: 230px;
    }
}


body.noWebp {

    .prospectus-main {

        &:not(.detailSelected) {
            background-image: url(../../img/pattterns/mesh-dark.png);
        }

        &.detailSelected {
            background-image: url(../../img/bg/courses.png);

            .infront {
                background-image: url(../../img/pattterns/mesh-dark.png);
            }
        }

        &:before {
            background-image: url(../../img/bg/prospectus-bg-2.jpg);
        }
    }
}
