.heading-selector {
	position: relative;
	margin-bottom: 4vh;

	@media (max-width: $tablet) {
		margin-top: 4em;
	}

	.head {
		justify-content: space-between;
		align-items: flex-start;
	}

	.container {
		max-width: 669px;
	}
}



.selector {
	transition: 0.2s ease-in-out opacity;

	&.hide {
		opacity: 0;
		user-select: none;
		pointer-events: none;
	}
}

$hexagon-width: 9em;
$hexagon-height: $hexagon-width*.55;
$hexagon-margin: 1em;
$honeycomb-max-width: 1200px;
$viewport-thereshold: $hexagon-width + $hexagon-margin;

.bubble {

	&__wrapper {
		width: 100vw;
		position:relative;
		min-height: 43em;
		overflow: visible;
		margin: 0;
		margin-left: calc(-50vw + 50%);
		z-index: 99999;
		overflow:auto;
		margin-bottom: 3em;
		mask-image:  linear-gradient(180deg, rgba(0,0,0,0) 0%,
	rgba(0,0,0,1) 15%,rgba(0,0,0,1) 85%, rgba(0,0,0,0) 100%);

	@media (max-width: $large-screen) {
		min-height: 22em;
		height: 22em;
		position: relative;
		overflow: scroll;
		scroll-behavior: smooth;
		-webkit-overflow-scrolling: touch;
	}

	@media (max-width: $large-screen) and (min-height: $tablet) {
		min-height: 34em;
		height: 34em;
	}

		&.two {
			min-height: 20em;

			@media (min-height: $tablet) {
				min-height: 34em;
				height: 34em;
				top: 50%;
			}
		}
	}

	&__scroll {
			transform: translate(var(--translateX), var(--translateY));
			position: absolute;
			top: 35%;
			left: 45%;
			display: grid;
			justify-content: center;
			font-size: var(--itemSize);
			grid-template-columns: repeat(20, 0.5em);
			grid-auto-rows: 0.5em;
			grid-gap: 0 0.07em;
			scroll-snap-type: mandatory;
			will-change: transform;
			backface-visibility: hidden;
			width: 15em;
			padding-left: 3em;

			@media (max-width: $desktop-s) {
				left: 50%;
				width: 240%;
				margin-left: 15%;
			}

			@media(max-width: $large-screen) {
				position: static;
				justify-content: center;
				align-items: center;
				transform: scale(0.7) translateX(-6em);
				width: 14em;
				padding-left: 2em;
				padding-right: 2em;
				margin-left: initial;
			}

			&.single-items {
				grid-template-columns: auto;
				grid-auto-rows: auto;
				top: 25%;
				left: 50%;

				.bubble__item-wrap {
					transform: scale(1) !important;
					opacity: 1 !important;

					&:first-of-type {
						grid-column-start: -25;
					}
				}
			}

			&.two {
				top: 25%;

				.bubble__inner {
					background-image: none;
					color: var(--white);

					&:after {
						background-color: var(--turquoise-dark);
					}

					&.active {

						&:after {
							background-color: var(--white);
						}
					}
				}
			}

		> div {
			grid-column-end: span 2;
			grid-row-end: span 2;
			height: 1em;
			width: 1em;
			border-radius: 50%;
			will-change: transform, opacity;
			backface-visibility: hidden;
			transform: scale(var(--scale)) !important;
			opacity: calc(var(--scale) * var(--scale)) !important;
			transition: 0.4s ease-in-out opacity, 0.4s ease-in-out transform, !important;
			scroll-snap-align: center;
			// box-shadow: 0 0 .2em 0 rgba(0,0,0,0.2);
			margin: 0.5em;

			@media (max-width: $large-screen) {
				transition: 0.3s ease-out;
			}

			&:first-of-type{
				grid-column-start: 5;
			}

			&:nth-of-type(7) {
				grid-column-start: 4;
			}

			&:nth-of-type(14) {
				grid-column-start: 3;
			}

			&:nth-of-type(22) {
				grid-column-start: 4;
			}

			&:nth-of-type(31) {
				grid-column-start: 3;
			}

			&:nth-of-type(39) {
				grid-column-start: 4;
			}
		}
	}

	&__inner {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		padding: 0.5em;
		overflow: hidden;
		color: var(--charcoal);
		text-align: center;
		line-height: $heading-line-height;
		z-index: 0;
		cursor: pointer;

		.title {
			user-select: none;
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: var(--turquoise);
			opacity: 0.8;
			z-index: -1;
			transition: 0.2s ease-in-out opacity;
		}

		&.active {

			&:after {
				background-color: var(--white);
				opacity: 0.9;
				z-index: 1;
				background-image: url('/img/icons/check.svg');
				background-position: 50%;
				background-size: 30%;
				background-repeat: no-repeat;
			}
		}
	}
}
