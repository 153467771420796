.reactour__helper {
    max-width: 300px !important;

    @media ( max-width: 789px ) {
        left: calc( (100vw - 300px) / 2 ) !important;
    }
}

.tooltip {
    position: relative;
    height: auto;
    color: var(--base);
    z-index: 23;

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: -32px;
        left: 50%;
        transform: translateX(-50%);
        border-color: transparent transparent var(--white)  transparent;
        border-style: solid;
        border-width: 1em;
    }

    &.five,
    &.three {
        &::after {
            display: none;
        }
    }

    &__inner {
        width: 100%;
        height: 100%;
        background-color: var(--white);
        border-radius: 8px;
        overflow: hidden;
    }

    &__top {
        height: calc(100% - 3rem);
        padding: 1em;

        h2 {
            margin: 0.75em 0;
        }

        .swipe {
            display: none;
            position: relative;
            width: 70%;
            height: 3em;
            margin: 0 auto;

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 1.5em;
                width: calc(100% - 1.5em);
                height: 100%;
                background: linear-gradient(90deg, #0b72b1 0%, rgba(11, 114, 177, 0) 100% );
                z-index: 0;
            }
        }

        .arrow {
            position: relative;
            width: 3em;
            height: 3em;
            justify-content: center;
            align-items: center;
            border-radius: 60%;
            overflow: hidden;
            background-color: var(--blue);
            z-index: 1;
        }
    }

    &__buttons {
        height: 3rem;
    }

    &__btn {
        width: 50%;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: var(--grey-light);
        color: var(--font);

        &:first-child {
            background-color: var(--grey-light);
            border-right: 1px solid var(--white);
        }

        &:last-child {
            background-color: var(--yellow);
        }

        &:first-child:last-child {
            width: 100%;
        }
    }

    &.two {

        .swipe {
            display: block;
        }
    }
}

.toolTipContainer:first-of-type {
    &:before {
        content:'';
        background-color: var(--sidebar-fade);
        width: 100%;
        top: 0;
        position: absolute;
        z-index: 60;
        left: 0;
        right: 0;
        bottom: 0;
        transition: 0.2s ease-in-out opacity;
    }
}

.mask {
    color: rgba( 0,0,0,0.5 );
}
