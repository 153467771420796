.logo_head {
    position: absolute;
    top: 24px;
    left: 24px;
}


.prospectus-main {

    .logo_head {
        top: -16px; // -60px;
        left: 0;

        @media ( max-width: 790px ) {
            left: 0;
            top: 0;
        }
    }
}
