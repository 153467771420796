.courses {
    background-color: var(--base);
    color: var(--white);
    padding: 80px 24px 40px;
    background-image: url(../../img/bg/courses.webp);
    z-index: 999;
    box-shadow: 1px 4px 30px 5px rgba( 0, 0, 0, 0.48);
    backface-visibility: hidden;
    min-height: 100vh;

    background-position: center;
    background-size: cover;
    width: 100%;
    position: absolute;
    top: 0;
    background-attachment: fixed;

    &.loading-screen {
        position: fixed;
        z-index: 900;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        min-height: initial;
    }

    .container {
        position: relative;
        z-index: 20;

        &.detail {
            overflow: hidden;
        }
    }

    &::after {
        content: "";
        background-image: url(../../img/pattterns/crosses.webp);
        background-position: 0 18px;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0;
        height: 400px;
        left: 0;
        right: 0;
        pointer-events: none;
        user-select: none;
        z-index: -1;
    }

    @media ( max-width: 768px ) {
        padding: 24px 16px;
    }
}

body.noWebp {

    .courses {
        background-image: url(../../img/bg/courses.png);

        &:after {
            background-image: url(../../img/pattterns/crosses.png);
        }
    }
}

.courseList {
    min-height: 80vh;
    position: relative;
    z-index: 10;
}

.courseListWrapper {
    height: 100% !important;
    transition: 0.2s ease-in-out opacity;

    &.detail {
        opacity: 0;
    }
}

.float-detail {
    z-index: 100;
    position: fixed;
    top: 80px;
    left: 24px;
    right: 24px;

    figure {
        max-height: calc( 100vh - 160px );
    }

    @media ( min-width: 899px ) {
        .content {
            max-height: calc( 100vh - 140px );

            .scroll {
                height: 100%;
            }
        }
    }

    @media ( max-width: 900px ) {
        left: 8px;
        right: 8px;
        max-width: calc( 100vw - 16px );
        overflow: auto;
        max-height: calc( 100vh - 140px );
    }
}
