.card-slider {

        .subtitle {
            margin-bottom: 16px;
        }

        .text {
            margin-bottom: 16px;
        }

    .cardCarousel {
        overflow: auto;
    }

    .innerCarousel {
        display: flex;
        flex-wrap: nowrap;
        padding: 8px;

        .card {
            flex-grow: 1;
            min-width: 200px;
            max-width: 200px;
            margin-right: 1em;
        }
    }

    .title {
        display: flex;
        justify-content: space-between;
        height: 1.5em;
        font-family: $heading-font-family;

        &.ticked {
            justify-content: flex-start;
        }

        .arrow {
            transform: rotate(180deg);
            transition: 0.2s ease-in-out transform;
        }

        &:hover,
        &:focus {

            .arrow {
                transform: translateX(16px) rotate(180deg);
            }
        }
    }

    &.can_toggle {
        padding: 0.5em 0.5em 0.5em 0;

        .carousel_toggle {
            max-height: 0;
            overflow: hidden;
            transition: 0.3s ease-in-out max-height;
            will-change: max-height;
            backface-visibility: hidden;
            visibility: hidden;

            .is-loading {

                .title {
                    animation: fadeIn 1.5s;
                    animation-iteration-count: infinite;
                    animation-direction: alternate-reverse;
                    height: 24px;
                    width: 100%;
                    background-color: rgba( 255, 255, 255, 0.1 );

                    @media (prefers-reduced-motion: reduce) {
                        animation: none;
                    }
                }
            }
        }
    }

    label {
        display:-webkit-box;
        -webkit-box-pack: justify;
        display: flex;
        justify-content: space-between;
        line-height: 1;
        margin: 0;
    }

    &.can_toggle {

        label {
            padding: 0;
        }
    }

    input[type=checkbox]:focus + {

        label {
            outline: 1px solid #065fcc;
        }
    }

    .img {
        width: 16px;
        display: block;
        transition: 0.3s ease-in-out transform;
    }

    .carousel_checkbox:checked ~ {

        label {

            img {
                transform: rotate(180deg);
            }
        }

        .carousel_toggle {
            max-height: 320px;
            visibility: visible;
        }
    }
}

.ca-container {

    .card-slider.can_toggle {
        padding: 0.5em;

        label {
            padding: 8px;
        }
    }
}

.tick {
    margin-left: 12px;
}

@keyframes fadeIn {

	from {
		opacity: 0.2;
	}

	to {
		opacity: 0.8;
	}
}
